/* ここにBootstrap以外のCSSを書かないこと */
$primary: #ab935a;
$secondary: #9b9b9b;
$custom-file-text: (
  en: 'Browse',
  ja: 'ファイルを選択'
);
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px
);
@import 'bootstrap/scss/bootstrap';

.btn-danger {
  color: white;
}

.btn {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  border-radius: 2px;
  padding: 12px 14px;
  font-weight: bold;
}

.btn-lg {
  font-size: 18px;
  padding: 16px 28px;
}

.btn-sm {
  font-size: 12px;
  padding: 8px 10px;
}

.btn-disable {
  opacity: 0.4;
}

.form-control {
  height: 44px;
  border-radius: 2px;
  border-color: rgba(0, 0, 0, 0.2);
}
